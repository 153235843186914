<template>
  <div>
    <v-dialog v-model="ModalDelete" persistent max-width="50%">
      <v-card>
        <v-card-title> Hapus Data </v-card-title>

        <v-divider class="mt-2 mb-5"></v-divider>

        <v-card-text>
          <v-form ref="form">
            <div>
              <h3>Apakah Anda Yakin Menghapus Data Ini?</h3>
            </div>

            <div>
              <span>Data yang telah dihapus tidak akan bisa dikembalikan!</span>
            </div>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions class="pa-3">
          <v-spacer></v-spacer>
          <v-btn color="gray" @click="closeModal()"> TIDAK </v-btn>

          <v-btn color="error" @click="add()" v-if="LBtn"> YA </v-btn>
          <v-btn color="error" loading v-else> YA </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import SHapus from "@/store/anjab/infojabatan/ikhtisarjab/hapus";
import SView from "@/store/anjab/infojabatan/ikhtisarjab/view";

export default {
  computed: {
    ModalDelete: {
      get() {
        return SHapus.state.ModalDelete;
      },
      set(value) {
        SHapus.commit("TModal", value);
      },
    },

    SData: {
      get() {
        return SHapus.state.data;
      },

      set(value) {
        console.log(value);
      },
    },
  },

  watch: {
    async ModalDelete() {},
  },

  data: () => ({
    token: Cookie.get("token"),
    LBtn: true,
  }),

  methods: {
    reset() {
      this.$refs.form.reset();
    },

    async add() {
      this.LBtn = false;

      const url = process.env.VUE_APP_API + "ikhtisar/hapus/" + this.SData.id;
      this.axios
        .delete(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.LBtn = true;
          if (res.data.success) {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("berhasilAlert", true);
            SView.commit("gagalAlert", false);
            SView.commit("success", res.data.success);
          } else {
            SView.commit("refreshData", true);
            SView.commit("alert", res.data.message);
            SView.commit("gagalAlert", true);
            SView.commit("berhasilAlert", false);
            SView.commit("success", res.data.success);
          }

          this.closeModal();
        })
        .catch((error) => {
          SView.commit("refreshData", true);
          SView.commit("alert", error.response.data.message);
          SView.commit("gagalAlert", true);
          SView.commit("berhasilAlert", false);
          SView.commit("success", error.response.data.success);
          console.log(error.response.status);
          console.log("Error", error.message);
          this.LBtn = true;

          this.closeModal();
        });
    },

    async closeModal() {
      await SView.commit("refreshData", true);
      this.reset();
      this.ModalDelete = false;
    },
  },
};
</script>
