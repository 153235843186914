<template>
  <div>
    <v-card flat>
      <v-data-table :headers="headers" :items="data">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Ikhtisar Jabatan</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-col cols="6" class="mt-4">
              <v-alert v-model="alertBerhasil" type="success" dense dismissible>
                {{ alertMassage }}
              </v-alert>
              <v-alert v-model="alertGagal" type="error" dense dismissible>
                {{ alertMassage }}
              </v-alert>
            </v-col>

            <v-spacer></v-spacer>

            <v-btn small color="primary" @click="tambah()">Tambah Data</v-btn>
          </v-toolbar>
        </template>
        <template v-slot:[`item.no`]="{ item }">
          {{
            data
              .map(function (x) {
                return x.ikhtisar_id;
              })
              .indexOf(item.ikhtisar_id) + 1
          }}
        </template>

        <template v-slot:[`item.ket`]="{ item }">
          <div class="py-2">
            {{ item.ket }}
          </div>
        </template>

        <template v-slot:[`item.action`]="{ item }">
          <v-row dense>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="edit(item)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  icon
                  v-bind="attrs"
                  v-on="on"
                  @click="hapus(item)"
                >
                  <v-icon small> mdi-delete </v-icon>
                </v-btn>
              </template>
              <span>Hapus</span>
            </v-tooltip>
          </v-row>
        </template>
      </v-data-table>
    </v-card>

    <CMadd />
    <CMedit />
    <CMhapus />
  </div>
</template>

<script>
import Cookie from "@/helper/cookie.js";

import CMadd from "@/components/anjab/infojabatan/ikhtisarjab/add";
import CMedit from "@/components/anjab/infojabatan/ikhtisarjab/edit";
import CMhapus from "@/components/anjab/infojabatan/ikhtisarjab/hapus";

import SAdd from "@/store/anjab/infojabatan/ikhtisarjab/add";
import SEdit from "@/store/anjab/infojabatan/ikhtisarjab/edit";
import SHapus from "@/store/anjab/infojabatan/ikhtisarjab/hapus";
import SView from "@/store/anjab/infojabatan/ikhtisarjab/view";

export default {
  components: {
    CMadd,
    CMedit,
    CMhapus,
  },

  computed: {
    refresh: {
      get() {
        return SView.state.refresh;
      },
    },

    alertMassage: {
      get() {
        return SView.state.alertMassage;
      },
    },

    success: {
      get() {
        return SView.state.success;
      },
      set(value) {
        SView.commit("alert", value);
      },
    },

    alertBerhasil: {
      get() {
        return SView.state.alertBerhasil;
      },
      set(value) {
        SView.commit("berhasilAlert", value);
      },
    },

    alertGagal: {
      get() {
        return SView.state.alertGagal;
      },
      set(value) {
        SView.commit("gagalAlert", value);
      },
    },
  },

  watch: {
    refresh() {
      this.getData();
      setTimeout(() => {
        this.alertGagal = false;
        this.alertBerhasil = false;
      }, 5000);
    },
  },

  data: () => ({
    token: Cookie.get("token"),
    ldata: true,

    headers: [
      {
        text: "Nomor",
        value: "no",
        width: "50px",
        align: "center",
        sortable: false,
      },
      { text: "Detail", align: "start", value: "ket" },
      { text: "Action", align: "center", value: "action", width: "100px", sortable: false },
    ],

    data: [],
  }),

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.ldata = true;
      const url =
        process.env.VUE_APP_API + "ikhtisar/getjab/" + this.$route.params.id;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.token,
          },
        })
        .then((res) => {
          this.ldata = false;
          this.data = res.data.mapData;
        })
        .catch((error) => {
          console.log(error.response.status);
          console.log("Error", error.message);
        });
    },

    tambah() {
      SAdd.commit("TModal", true);
    },

    edit(item) {
      SEdit.commit("TModal", true);
      SEdit.commit("VData", item);
    },

    hapus(item) {
      SHapus.commit("TModal", true);
      SHapus.commit("VData", item);
    },
  },
};
</script>
